import { useCallback } from 'react'

import type { LinxMetaCategory } from '../types'
import { useLinxEventDispatch } from './useLinxEventDispatch'

interface UseViewHomeProps {
  searchId?: string
  categories: string[]
}

export const useLinxCategoryView = ({
  searchId,
  categories,
}: UseViewHomeProps) => {
  const { dispatchLinxEvent } = useLinxEventDispatch()

  const verifyTypePage = (category: string[]) => {
    return category.length > 1 ? 'subcategory' : 'category'
  }

  const sendCategoryViewEvent = useCallback(() => {
    const linxMetaCategory: LinxMetaCategory = {
      page: verifyTypePage(categories),
      categories,
      searchId,
    }

    if (!searchId) {
      delete linxMetaCategory.searchId
    }

    return dispatchLinxEvent(linxMetaCategory)
  }, [dispatchLinxEvent, categories, searchId])

  return { sendCategoryViewEvent }
}
